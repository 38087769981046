import React from 'react';
import '../../styles/components/case-templates/case-footer.scss';
import {StaticQuery, graphql} from 'gatsby';
import _ from 'lodash';
import {navigate} from 'gatsby';
import Img from 'gatsby-image';
import '../../styles/utils/general.scss';
import * as routes from '../../utils/constants/routes';
import {isSmallScreen} from '../../utils/helpers';

//TODO ligar às traduções de conteudo, titulo em cima da imagem, links

const NextArrow = props => {
  return (
    <button type="button" className="footer-arrow-button">
      <svg width="50px" height="50px" fill="white">
        <path
          transform="translate(1.000000, 1.000000)"
          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
          id="path-1"
        />
        <polygon
          transform="translate(15.000000, 14.000000)"
          id="path-3"
          points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
        />
      </svg>
    </button>
  );
};

const PrevArrow = props => {
  return (
    <button type="button" className="footer-arrow-button">
      <svg width="50px" height="50px" fill="white">
        <path
          transform="translate(1.000000, 1.000000)"
          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
          id="path-1"
        />
        <polygon
          transform="translate(13.000000, 14.000000)"
          id="path-3"
          points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
        />
      </svg>
    </button>
  );
};

const CaseFooter = props => {
  return (
    <StaticQuery
      query={graphql`
        query Footer {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  title
                  language
                  path
                  listed
                  template
                  productTypeShort
                  segmentTag
                  solutionTag
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1920, maxHeight: 1080) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <CaseFooterComponent data={data} {...props} />}
    />
  );
};

const CaseFooterComponent = props => {
  //TODO major refactor to hold state for currentCasesArray
  //TODO abstract messages to own data json

  const data = props.data.allMarkdownRemark.edges;

  var currentCasesArray = []; //initialize var that stores state for casesArray

  var nextCase = {};

  //algorithm to rank cases by similarity with current
  //productType +2 , segmentTag + 1, solutionTag + 1 points

  var casesArray = _.chain (data)
    .map (d => d.node.frontmatter)
    .filter (d => d['language'] === props.locale)
    .filter (d => d['listed'] === true)
    .filter (d => d['template'] !== 'LP')
    .map (d => {
      var similarityScore = 0;

      if (d['productTypeShort'] === props.productTypeShort) similarityScore++;

      _.map (d['segmentTag'], d => {
        _.map (props.segmentTag, el => {
          if (d === el) {
            similarityScore += 2;
          }
        });
      });

      _.map (d['solutionTag'], d => {
        _.map (props.solutionTag, el => {
          if (d === el) {
            similarityScore++;
          }
        });
      });

      return {
        title: d.title,
        path: d.path,
        similarityScore: +similarityScore,
        image: d.image,
      };
    })
    .orderBy (['similarityScore'], ['desc'])
    .value ();

  /*
    algorithm checks if it's the first time that component is loaded
    if it is currentCasesArray is what comes from original data after ranking
    if not currenCasesArray comes from payload from link
  */

  if (props.previousPath === '' || props.previousPathForPage === '') {
    currentCasesArray = casesArray;

    //remove current and previous cases from nextCase possibilities

    _.remove (currentCasesArray, d => d.path === props.path);

    _.remove (currentCasesArray, d => d.path === props.previousPath);

    nextCase = currentCasesArray[0];
  } else {
    var currentCaseFromProps = [];

    if (!props.currentCasesArray) {
      currentCaseFromProps = casesArray;
    } else {
      currentCaseFromProps = props.currentCasesArray;
    }

    //when all projects were read return to original list
    if (currentCaseFromProps.length === 1) {
      currentCasesArray = casesArray;

      //remove current and previous cases from nextCase possibilities

      _.remove (currentCasesArray, d => d.path === props.previousPath);

      nextCase = props.currentCasesArray[0];
    } else {
      currentCasesArray = currentCaseFromProps;

      _.remove (currentCasesArray, d => d.path === props.path);

      _.remove (currentCasesArray, d => d.path === props.previousPath);

      nextCase = currentCasesArray[0];
    }
  }

  const previousPath = props.path;

  const localeForLinkPaths = props.locale === 'pt' ? 'pt' : '';

  return (
    <div className="case-footer-container">
      <div className="image-footer-wrapper">
        <div
          className="image-container"
          onClick={() => {
            navigate (`${localeForLinkPaths}/${nextCase.path}`, {
              state: {
                previousPath: previousPath,
                currentCasesArray: currentCasesArray,
              },
            });
          }}
        >
          <Img fluid={nextCase.image.childImageSharp.fluid} />
          <div className="image-footer-content shadow">
            <div className="image-footer-content-next-project shadow">
              <p>
                {props.locale === 'en' ? 'Next Project' : 'Próximo Projecto'}
              </p>
            </div>
            <div className="image-footer-content-title-project shadow">
              <h1 style={{fontSize: isSmallScreen () && '34px'}}>
                {nextCase.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-controls-wrapper">
        <div className="footer-controls">
          <div
            className="back-arrow-footer complete-arrow-footer"
            onClick={() =>
              navigate (`${localeForLinkPaths}/${routes.LANDING}`, {
                state: {
                  comesFromPageFooter: true,
                },
              })}
          >
            <PrevArrow />
            <div className="arrow-footer-text-left typo-body2-white">
              {props.locale === 'en' ? 'all projects' : 'todos os projectos'}
            </div>
          </div>

          <div
            className="next-arrow-footer complete-arrow-footer"
            onClick={() => {
              navigate (`${localeForLinkPaths}/${nextCase.path}`, {
                state: {
                  previousPath: previousPath,
                  currentCasesArray: currentCasesArray,
                },
              });
            }}
          >
            <div className="arrow-footer-text-right typo-body2-white">
              {props.locale === 'en' ? 'next project' : 'próximo projeto'}
            </div>
            <NextArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseFooter;
/*
<Img fluid={nextCase.image.childImageSharp.fluid} />
*/

/*
<div className="button-primary call-to-action-footer">
  <LocalizedLink to={routes.CONTACTS} locale={props.locale}>
    {props.locale === 'en' ? 'speak with us' : 'fale connosco'}
  </LocalizedLink>
</div>;

*/
