import React from 'react';
import Img from 'gatsby-image';
import '../../styles/components/case-templates/main-highlight.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';

const MainHighlight = ({textContent, image, paddingTop, paddingBottom}) => {
  return (
    <div
      className="highlight-wrapper"
      style={
        paddingTop &&
        image && {paddingTop: paddingTop, paddingBottom: paddingBottom}
      }
    >
      {(textContent || !image) &&
        <div className="highlight-text-wrapper">

          <div className="highlight-text-content">
            <PerfectScrollbar>{textContent}</PerfectScrollbar>
          </div>

        </div>}
      {image &&
        <div className="background-image-highlight">
          <Img
            className="background-image-highlight-component"
            fluid={image.childImageSharp.fluid}
          />
          <div className="highlight-picture-filter" />
        </div>}

    </div>
  );
};

export default MainHighlight;
