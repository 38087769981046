import React from 'react';
import remark from 'remark';
import remarkHtml from 'remark-html';
import '../../styles/components/case-templates/simple-text-block.scss';

const SimpleTextBlock = ({
  text,
  header,
  textColor,
  paddingTop,
  paddingBottom,
}) => {
  var content = remark ().use (remarkHtml).processSync (text).toString ();

  //if into view - ternary to activate class of animation

  /*
  function isScrolledIntoView (el) {
    var rect = el.getBoundingClientRect ();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    //var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible(document.getElementsByClassName()) = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }
*/

  return (
    <div
      className="simple-text-block-container"
      style={text && {paddingTop: paddingTop, paddingBottom: paddingBottom}}
    >
      {header &&
        text &&
        <div className="simple-text-block-header-wrapper">

          <div className="simple-text-block-header">
            <h4>{header}</h4>
          </div>
        </div>}
      {text &&
        <div
          className="simple-text-block"
          dangerouslySetInnerHTML={{__html: content}}
          style={{
            color: textColor,
            fontSize: '18px',
            lineHeight: '32px',
          }}
        />}
    </div>
  );
};

export default SimpleTextBlock;
