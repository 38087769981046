import React from 'react';
import LogoLoader from '../Utils/LogoLoader';
import '../../styles/components/case-templates/logo-menu.scss';
import Dock from 'react-dock';
import '../../styles/utils/general.scss';
import {navigate} from 'gatsby';
import {isSmallScreen, isLandscape} from '../../utils/helpers';

//TODO abstract messages to own component

function IconAllCases (props) {
  const iconFill = props.iconFill;

  return (
    <svg
      className="all-cases"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      aria-labelledby="title"
    >
      <title id="title">IconAllCases</title>
      <path
        fill={iconFill}
        d="M4,9 L12,9 L12,17 L4,17 L4,9 Z M4,20 L12,20 L12,28 L4,28 L4,20 Z M14,9 L22,9 L22,17 L14,17 L14,9 Z M14,20 L22,20 L22,28 L14,28 L14,20 Z M24,9 L32,9 L32,17 L24,17 L24,9 Z M24,20 L32,20 L32,28 L24,28 L24,20 Z"
      />
    </svg>
  );
}

class LogoMenu extends React.Component {
  state = {
    isVisible: false,
  };

  render () {
    const dockStyles = {
      backgroundColor: '#0033a0',
    };

    return (
      <div className="logo-menu-button-wrapper">
        <div
          className="logo-button"
          onClick={() => this.setState ({isVisible: !this.state.isVisible})}
        >
          <LogoLoader
            isLogoMenu={true}
            productTypeShort={this.props.productTypeShort}
            fill={this.props.logoMenuColor}
          />
        </div>
        <Dock
          position="right"
          isVisible={this.state.isVisible}
          dockStyle={dockStyles}
          fluid={true}
          size={
            isSmallScreen () && !isLandscape ()
              ? 0.85
              : isSmallScreen () && isLandscape () ? 0.5 : 0.17
          }
        >
          <div className="dock-content">
            <div
              className="close-dock-button"
              onClick={() => this.setState ({isVisible: false})}
            >
              <svg width="36px" height="36px">
                <polygon
                  fill={'white'}
                  points="29 9.21571429 26.7842857 7 18 15.7842857 9.21571429 7 7 9.21571429 15.7842857 18 7 26.7842857 9.21571429 29 18 20.2157143 26.7842857 29 29 26.7842857 20.2157143 18"
                />
              </svg>
            </div>
            <div className="dock-message">
              <h4>
                {this.props.locale === 'pt'
                  ? 'Quer ver outros projetos?'
                  : 'Do you want to see other projects?'}
              </h4>
            </div>
            <div>
              <div className="menu-projects-logos">
                <div
                  className="menu-all-projects-logo-wrapper menu-projects-logo-wrapper"
                  onClick={() => {
                    navigate ('/', {
                      state: {
                        productTypeShortFromSideMenu: 'all',
                      },
                    });
                  }}
                >
                  <div className="menu-all-projects-logo menu-product-type">
                    <IconAllCases iconFill="white" />
                  </div>
                  <div className="logo-menu-caption typo-body2-white">

                    {this.props.locale === 'pt'
                      ? 'Todos os projetos'
                      : 'All projects'}
                  </div>
                </div>
                <div
                  className="mls-projects-logo-wrapper menu-projects-logo-wrapper"
                  onClick={() => {
                    navigate ('/', {
                      state: {
                        productTypeShortFromSideMenu: 'mls',
                      },
                    });
                  }}
                >
                  <div className="mls-projects-logo menu-product-type">
                    <LogoLoader productTypeShort={'mls'} fill={'white'} />
                  </div>
                  <div className="logo-menu-caption typo-body2-white">
                    {this.props.locale === 'pt'
                      ? 'Soluções para Gestão e Logística'
                      : 'Management and Logistics Solutions'}
                  </div>
                </div>
                <div
                  className="ivp-projects-logo-wrapper menu-projects-logo-wrapper"
                  onClick={() => {
                    navigate ('/', {
                      state: {
                        productTypeShortFromSideMenu: 'ivp',
                      },
                    });
                  }}
                >
                  <div className="ivp-projects-logo menu-product-type">
                    <LogoLoader productTypeShort={'ivp'} fill={'white'} />
                  </div>
                  <div className="logo-menu-caption typo-body2-white">
                    {this.props.locale === 'pt'
                      ? 'Apresentações Virtuais e Imersivas'
                      : 'Immersive and Virtual Presentations'}
                  </div>
                </div>
                <div
                  className="ie-projects-logo-wrapper menu-projects-logo-wrapper"
                  onClick={() => {
                    navigate ('/', {
                      state: {
                        productTypeShortFromSideMenu: 'ie',
                      },
                    });
                  }}
                >
                  <div className="ie-projects-logo menu-product-type">
                    <LogoLoader productTypeShort={'ie'} fill={'white'} />
                  </div>
                  <div className="logo-menu-caption typo-body2-white">
                    {this.props.locale === 'pt'
                      ? 'Experiências Interativas'
                      : 'Interactive Experiences'}
                  </div>
                </div>
                <div
                  className="avc-projects-logo-wrapper menu-projects-logo-wrapper"
                  onClick={() => {
                    navigate ('/', {
                      state: {
                        productTypeShortFromSideMenu: 'ivp',
                      },
                    });
                  }}
                >
                  <div className="avc-projects-logo menu-product-type">
                    <LogoLoader productTypeShort={'avc'} fill={'white'} />
                  </div>
                  <div className="logo-menu-caption typo-body2-white">
                    {this.props.locale === 'pt'
                      ? 'Conteúdos Visuais Avançados'
                      : 'Advanced Visual Content'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dock>
      </div>
    );
  }
}

export default LogoMenu;

//  <LogoLoader productTypeShort={this.props.productTypeShort} />

/*
onClick={() => {
    navigate (`${localeForLinkPaths}/${nextCase.path}`, {
      state: {
        previousPath: previousPath,
        currentCasesArray: currentCasesArray,
      },
    });
  }}


*/
