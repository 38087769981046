import React from 'react'
import '../../styles/utils/general.scss'

const AdvantageLogoLoader = ({ logo }) => {
  let advantageLogo = null

  switch (logo) {
    case 'custom-network':
      advantageLogo = (
        <svg width="36px" height="36px">
          <path
            fill="white"
            d="M6.66447217,22.0549381 L11.4009045,11.0406084 C10.5434513,10.3069683 10,9.21694908 10,8 C10,5.790861 11.790861,4 14,4 C16.209139,4 18,5.790861 18,8 C18,9.02326995 17.6157656,9.95679715 16.9837049,10.6641735 L23.2519719,22.0698243 C23.4942947,22.0239839 23.7443465,22 24,22 C24.1994701,22 24.3955301,22.0146006 24.5871694,22.0427915 L27.9240885,13.4198329 C26.7704731,12.7180627 26,11.4490082 26,10 C26,7.790861 27.790861,6 30,6 C32.209139,6 34,7.790861 34,10 C34,12.209139 32.209139,14 30,14 C29.9481786,14 29.8965873,13.9990146 29.8452439,13.9970614 L26.4299875,22.8224521 C27.3844692,23.5534833 28,24.7048592 28,26 C28,28.209139 26.209139,30 24,30 C21.790861,30 20,28.209139 20,26 C20,24.7644723 20.5601712,23.6597796 21.4403943,22.9260412 L15.3142906,11.7790686 C14.9026447,11.9222146 14.4604062,12 14,12 C13.7259729,12 13.4583816,11.9724448 13.1998459,11.9199542 L8.49100382,22.8701241 C9.4106205,23.6029975 10,24.7326703 10,26 C10,28.209139 8.209139,30 6,30 C3.790861,30 2,28.209139 2,26 C2,23.790861 3.790861,22 6,22 C6.22637517,22 6.4483582,22.018805 6.66447217,22.0549381 Z"
          />
        </svg>
      )
      break
    case 'custom-share-arrow':
      advantageLogo = (
        <svg width="36px" height="36px">
          <path
            fill="white"
            d="M22,21.9998195 C21.9727799,21.9999397 21.9454354,22 21.9179658,22 C14.1239502,22 8.84343754,25.0768869 6.07642793,31.2306608 C5.71609096,17.8578211 11.0239483,11.1147516 22,11.0014522 L22,6 L33.0019629,17.0019629 L22,27.3992972 L22,21.9998195 Z"
          />
        </svg>
      )
      break
    case 'custom-audience':
      advantageLogo = (
        <svg width="36px" height="36px">
          <path
            fill="white"
            d="M29,22 C27.3431458,22 26,20.6568542 26,19 C26,17.3431458 27.3431458,16 29,16 C30.6568542,16 32,17.3431458 32,19 C32,20.6568542 30.6568542,22 29,22 Z M18,22 C16.3431458,22 15,20.6568542 15,19 C15,17.3431458 16.3431458,16 18,16 C19.6568542,16 21,17.3431458 21,19 C21,20.6568542 19.6568542,22 18,22 Z M7,22 C5.34314575,22 4,20.6568542 4,19 C4,17.3431458 5.34314575,16 7,16 C8.65685425,16 10,17.3431458 10,19 C10,20.6568542 8.65685425,22 7,22 Z M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z M24,15 C22.3431458,15 21,13.6568542 21,12 C21,10.3431458 22.3431458,9 24,9 C25.6568542,9 27,10.3431458 27,12 C27,13.6568542 25.6568542,15 24,15 Z M18,8 C16.3431458,8 15,6.65685425 15,5 C15,3.34314575 16.3431458,2 18,2 C19.6568542,2 21,3.34314575 21,5 C21,6.65685425 19.6568542,8 18,8 Z M4,24 L10,24 C11.1045695,24 12,24.8954305 12,26 L12,32 C12,33.1045695 11.1045695,34 10,34 L4,34 C2.8954305,34 2,33.1045695 2,32 L2,26 C2,24.8954305 2.8954305,24 4,24 Z M15,24 L21,24 C22.1045695,24 23,24.8954305 23,26 L23,32 C23,33.1045695 22.1045695,34 21,34 L15,34 C13.8954305,34 13,33.1045695 13,32 L13,26 C13,24.8954305 13.8954305,24 15,24 Z M26,24 L32,24 C33.1045695,24 34,24.8954305 34,26 L34,32 C34,33.1045695 33.1045695,34 32,34 L26,34 C24.8954305,34 24,33.1045695 24,32 L24,26 C24,24.8954305 24.8954305,24 26,24 Z"
          />
        </svg>
      )
      break
    case 'custom-star':
      advantageLogo = (
        <svg width="36px" height="36px">
          <path
            fill="white"
            d="M18.277892,23.480083 L12.097892,27.4063987 L13.737892,20.0063987 L8.27789197,15.0274514 L15.467892,14.3853461 L18.277892,7.40639874 L21.087892,14.3853461 L28.277892,15.0274514 L22.817892,20.0063987 L24.457892,27.4063987 L18.277892,23.480083 Z M3,29.5212194 L4.146253,27.3976485 C13.3068904,32.2786463 22.585948,32.2786463 32.1210554,27.388805 L33.2334758,29.5300629 C22.9947472,34.7807388 12.8710454,34.7807388 3,29.5212194 Z M3,5.94574574 C12.8710454,0.686226272 22.9947472,0.686226272 33.2334758,5.93690221 L32.1210554,8.07816011 C22.585948,3.18831877 13.3068904,3.18831877 4.146253,8.06931658 L3,5.94574574 Z"
          />
        </svg>
      )
      break
    case 'custom-location-pin':
      advantageLogo = (
        <svg width="36px" height="36px">
          <path
            fill="white"
            d="M18,2 C11.9185714,2 7,7.008 7,13.2 C7,21.6 18,34 18,34 C18,34 29,21.6 29,13.2 C29,7.008 24.0814286,2 18,2 Z M18,17 C15.792,17 14,15.208 14,13 C14,10.792 15.792,9 18,9 C20.208,9 22,10.792 22,13 C22,15.208 20.208,17 18,17 Z"
          />
        </svg>
      )
      break
    default:
      advantageLogo = (<span className="material-icons md-36 md-white">{logo}</span>)
      break
  }

  return <>{advantageLogo}</>
}

export default AdvantageLogoLoader
