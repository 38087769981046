import React from 'react';
//import remark from 'remark';
//import remarkHtml from 'remark-html';
import _ from 'lodash';
import '../../styles/components/case-templates/advantages-block.scss';
import '../../styles/utils/general.scss';
import Img from 'gatsby-image';
import AdvantageLogoLoader from '../../components/Utils/AdvantageLogoLoader';

const AdvantagesBlock = ({
  advantagesContent,
  bgPicture,
  header,
  paddingTop,
  paddingBottom,
}) => {
  //var content = remark ().use (remarkHtml).processSync (text).toString ();

  const advantagesPairs = _.chunk (advantagesContent, 2);

  const advantages = _.map (advantagesPairs, (d, index) => {
    return d.length < 2
      ? <div key={index} className="advantages-row">
          <div className="advantage-left">
            <div className="advantage-content-wrapper">
              <div className="advantage-logo">
                <AdvantageLogoLoader logo={d[0].logo} />
              </div>
              <div className="advantage-text">
                {d[0].text}
              </div>

            </div>
          </div>
          <div className="advantage-right">
            <div className="advantage-content-wrapper">
              <div className="advantage-logo" />
              <div className="advantage-text" />

            </div>
          </div>
        </div>
      : <div key={index} className="advantages-row">

          <div className="advantage-left">
            <div className="advantage-content-wrapper">
              <div className="advantage-logo">
                <AdvantageLogoLoader logo={d[0].logo} />
              </div>
              <div className="advantage-text">
                {d[0].text}
              </div>

            </div>
          </div>
          <div className="advantage-right">
            <div className="advantage-content-wrapper">
              <div className="advantage-logo">
                <AdvantageLogoLoader logo={d[1].logo} />
              </div>
              <div className="advantage-text">{d[1].text}</div>

            </div>
          </div>

        </div>;
  });

  //  {bgPicture && <Img fluid={bgPicture.childImageSharp.fluid} />}

  return (
    <div
      className="advantages-block-wrapper"
      style={
        advantagesContent &&
        header && {
          marginTop: paddingTop,
          marginBottom: paddingBottom,
          height: '800px',
        }
      }
    >

      {advantagesContent &&
        header &&
        <div className="background-picture-advantages">
          {bgPicture && <Img fluid={bgPicture.childImageSharp.fluid} />}
        </div>}

      {advantagesContent &&
        header &&
        <div className="advantages-background-purple-filter" />}
      {advantagesContent &&
        header &&
        <div className="advantages-container">
          <div className="advantages-header" style={{color: 'white'}}>
            <h2>{header}</h2>
          </div>
          <div className="advantages-content-wrapper typo-body2-white">
            {advantages}
          </div>
        </div>}
    </div>
  );
};

export default AdvantagesBlock;
