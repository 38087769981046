import React from 'react';
import '../../styles/components/case-templates/360-carousel.scss';
import Slider from 'react-slick';
import _ from 'lodash';
import Img from 'gatsby-image';
import '../../styles/utils/general.scss';
import OracleMultimediaGallery
  from './MultimediaGallery/OracleMultimediaGallery';
import {
  isSmallScreen,
  isSmallMediumScreen,
  isLandscape,
} from '../../utils/helpers';

const NextArrow = props => {
  const {className, onClick, to} = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
      aria-label={to}
    >
      <svg width="50px" height="50px" fill="white">
        <path
          transform="translate(1.000000, 1.000000)"
          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
          id="path-1"
        />
        <polygon
          transform="translate(15.000000, 14.000000)"
          id="path-3"
          points="6.82427674 1.22222222 16.6020545 11 6.82427674 20.7777778 5.5 19.453501 13.953501 11 5.5 2.54649896"
        />
      </svg>
    </button>
  );
};

const PrevArrow = props => {
  const {className, onClick, to} = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
      aria-label={to}
    >
      <svg width="50px" height="50px" fill="white">
        <path
          transform="translate(1.000000, 1.000000)"
          d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,46 C36.1502645,46 46,36.1502645 46,24 C46,11.8497355 36.1502645,2 24,2 C11.8497355,2 2,11.8497355 2,24 C2,36.1502645 11.8497355,46 24,46 Z"
          id="path-1"
        />
        <polygon
          transform="translate(13.000000, 14.000000)"
          id="path-3"
          points="15.2777778 1.22222222 5.5 11 15.2777778 20.7777778 16.6020545 19.453501 8.14855348 11 16.6020545 2.54649896"
        />
      </svg>
    </button>
  );
};

class Carousel360 extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      activeSlide: 1,
    };
  }

  render () {
    const imagesFor360Gallery = _.map (this.props.images, d => (
      <div id="gallery360-image--wrapper">
        <div
          className="gallery-image-inner-wrapper"
          style={{
            position: 'relative',
          }}
        >
          <Img
            key={d.publicURL}
            fluid={d.linkPhoto.childImageSharp.fluid}
            className="gallery-360-image"
          />
          <OracleMultimediaGallery
            textFirstLine={d.oracleMessage.firstLine}
            textSecondLine={d.oracleMessage.secondLine}
            mediaType={'photo'}
          />
        </div>
      </div>
    ));

    var totalSlides = null;

    if (this.props.images) {
      totalSlides = this.props.images.length;
    }

    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState ({activeSlide: next + 1}),
      nextArrow: <NextArrow to="next" />,
      prevArrow: <PrevArrow to="prev" />,
    };

    return (
      <div
        className="carousel360-wrapper"
        style={
          this.props.images && {
            marginTop: this.props.paddingTop,
            marginBottom: this.props.paddingBottom,
            height: '800px',
            maxHeight: isSmallScreen () && !isLandscape ()
              ? '420px'
              : isSmallMediumScreen () ? '550px' : undefined,
          }
        }
      >
        {this.props.images &&
          <div className="symbol360-container">
            <svg width="50px" height="50px">
              <path
                d="M1.75442761,26.8051692 C1.51422895,26.1720554 1.38888889,25.5202701 1.38888889,24.854736 C1.38888889,20.7595946 6.13444397,17.1850054 13.1837145,15.2777778 C12.7406542,16.5586882 12.5,17.9340545 12.5,19.3656287 C12.5,19.6445134 12.509133,19.921265 12.5271163,20.1956007 C7.4615969,21.6712416 3.67809584,24.0255286 2.14178451,26.8051692 C4.38182452,30.8580626 11.399278,34.0066755 20.2023345,34.845905 L20.2023345,39.5793319 C9.46813347,38.555999 1.38888889,34.0989173 1.38888889,28.7556024 C1.38888889,28.0900682 1.51422895,27.438283 1.75442761,26.8051692 Z M47.6713385,26.8083032 C46.154872,24.0612319 42.4437186,21.7293428 37.4691975,20.2500298 C37.4896182,19.9578827 37.5,19.6629681 37.5,19.3656287 C37.5,17.9552627 37.2664233,16.5994514 36.8358434,15.3347682 C43.7702132,17.2556569 48.422503,20.8000356 48.422503,24.854736 C48.422503,25.5202701 48.2971629,26.1720554 48.0569642,26.8051692 C48.2971629,27.438283 48.422503,28.0900682 48.422503,28.7556024 C48.422503,34.6047334 40.0697781,39.391902 27.8224136,39.7754762 C27.8193197,39.7807793 27.8193197,40.9809264 27.8224136,43.3759177 C24.4087379,39.4242247 22.7019001,37.4483782 22.7019001,37.4483782 C22.7019001,37.4483782 24.4087379,35.3396907 27.8224136,31.1223158 C27.7990791,33.718096 27.7951767,35.0247956 27.8107065,35.0424146 C38.0481439,34.7241774 45.2440926,31.3291802 47.6713385,26.8083032 Z M25,29.1666667 C19.6305649,29.1666667 15.2777778,24.8138795 15.2777778,19.4444444 C15.2777778,14.0750094 19.6305649,9.72222222 25,9.72222222 C30.3694351,9.72222222 34.7222222,14.0750094 34.7222222,19.4444444 C34.7222222,24.8138795 30.3694351,29.1666667 25,29.1666667 Z"
                fill="white"
              />
            </svg>
          </div>}
        {this.props.images &&
          <div className="picture360-frame">
            {imagesFor360Gallery &&
              <Slider {...settings}>{imagesFor360Gallery}</Slider>}
          </div>}
        {this.props.images &&
          <div
            className="gallery360-bottom-bar typo-body2-white-bold"
            style={
              this.props.messageFor360Gallery
                ? {justifyContent: 'space-between'}
                : {justifyContent: 'flex-end'}
            }
          >
            {this.props.messageFor360Gallery &&
              <div className="gallery360-link-external-wrapper">
                <div className="gallery360-link-external-logo">
                  {this.props.messageFor360Gallery &&
                    <svg width="20px" height="20px" fill="white">
                      <path
                        d="M3.33333333,16.6666667 L16.6945847,16.6666667 L16.6945847,11.1111111 L18.3612514,11.1111111 L18.3612514,18.3333333 L16.6945847,18.3333333 L2.22222222,18.3333333 L1.66666667,18.3333333 L1.66666667,2.22222222 L2.22222222,2.22222222 L9.44444444,2.22222222 L9.44444444,3.88888889 L3.33333333,3.88888889 L3.33333333,16.6666667 Z M15.4044011,3.88888889 L12.1943041,3.88888889 L12.1943041,2.22222222 L17.1943041,2.22222222 L18.3333333,2.22222222 L18.3333333,8.33333333 L16.6666667,8.33333333 L16.6666667,4.98364597 L8.26593314,13.3843795 L7.08742184,12.2058682 L15.4044011,3.88888889 Z"
                        id="path-1"
                      />
                    </svg>}
                </div>
                <div className="gallery360-link-external-message">
                  <a
                    className="typo-body2-white-bold"
                    href={this.props.externalLink360Gallery}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.messageFor360Gallery}
                  </a>
                </div>
              </div>}
            <div className="gallery360-pagination">
              {this.state.activeSlide}/{totalSlides}
            </div>
          </div>}
      </div>
    );
  }
}
export default Carousel360;
