import React from 'react';
import {isSmallScreen, isSmallMediumScreen} from '../../../utils/helpers';

function OracleMultimediaGallery({
  dimensionsToMoveOracle,
  textFirstLine,
  textSecondLine,
  mediaType,
}) {
  var logoHeight, logoWidth;

  if (isSmallScreen ()) {
    logoHeight = '20px';
    logoWidth = '20px';
  } else if (isSmallMediumScreen ()) {
    logoHeight = '35px';
    logoWidth = '35px';
  } else {
    logoHeight = '50px';
    logoWidth = '50px';
  }

  return (
    <div
      className={`multimedia-gallery-oracle multimedia-gallery-oracle-${mediaType}`}
      style={{right: dimensionsToMoveOracle}}
    >
      <div className="multimedia-gallery-oracle-logo-container">
        <svg
          className={'shadow'}
          height={logoHeight}
          width={logoWidth}
          viewBox="0 0 50 50"
        >
          <path
            fill={'white'}
            d="M25,3.77358491 L43.3962264,35.8490566 L31.1321652,35.8490566 L25,25.1569616 L18.8678348,35.8490566 L6.60377358,35.8490566 L25,3.77358491 Z M0,47.1698113 L6.60391857,35.8490566 L13.2075472,47.1698113 L0,47.1698113 Z M18.8679245,35.8490566 L31.1320755,35.8490566 L25.0001346,47.1698113 L18.8679245,35.8490566 Z M36.7924528,47.1698113 L43.3963714,35.8490566 L50,47.1698113 L36.7924528,47.1698113 Z"
          />
        </svg>
      </div>
      <div className="multimedia-gallery-oracle-content">
        <div className="multimedia-gallery-oracle-content-title typo-oracle-title ">
          {textFirstLine}
        </div>
        <div className="multimedia-gallery-oracle-content-sub-title typo-oracle-sub-title">
          {textSecondLine}
        </div>
      </div>
    </div>
  );
}

export default OracleMultimediaGallery;

//Calçada Portuguesa Escala 1x1
//Pormenor de chão real em escala real
