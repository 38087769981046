import React from 'react';
import _ from 'lodash';
import '../../styles/components/case-templates/case-classificaton.scss';
import '../../styles/utils/general.scss';
import LogoLoader from '../Utils/LogoLoader';
import ReactGA from 'react-ga';
import {isSmallScreen} from '../../utils/helpers';

const CaseClassification = ({
  title,
  segmentTag,
  areaTag,
  solutionTag,
  tags,
  locale,
  productTypeShort,
  productType,
  client,
  brochureURL,
  brochureMessage,
  readingTime,
}) => {
  const tagList = _.map (solutionTag, (d, index) => <div key={index}>{d}</div>);

  return (
    <div>
      {!isSmallScreen ()
        ? <div className="case-classification--container">

            <div className="case-classification--left-column">
              <h1
                className="case-classification--title"
                style={{
                  marginBottom: '0',
                  fontSize: '58px',
                  letterSpacing: '-0.84px',
                  marginTop: '0',
                }}
              >
                {title}
              </h1>
              <div className="typo-body2 case-classification--main-tags">
                {segmentTag}
              </div>

              <div className="gray typo-captions case-classification--reading-time">
                {readingTime}
              </div>
              <div className="case-classification--tag-list-wrapper">
                <div
                  style={{fontWeight: 'bold'}}
                  className="gray typo-body2 case-classification--tag-list-title"
                >
                  {locale === 'pt' ? 'Áreas de Intervenção' : 'Solution Types'}
                </div>
                <div className="gray typo-body2 case-classification--tag-list-tags">
                  {tagList}
                </div>
              </div>
            </div>
            <div className="case-classification--right-column">
              <div className="case-classification--product-wrapper">
                <div className="case-classification--product-logo">
                  <LogoLoader productTypeShort={productTypeShort} />
                </div>
                <div className="case-classification--product-type">
                  {productType}
                </div>
                <div className="typo-body2">{areaTag}</div>
              </div>
              <div className="gray typo-body2 case-classification--client">
                <div style={{fontWeight: 'bold'}}>Client</div>
                <div>{client}</div>
              </div>
              <div className="gray typo-body2">
                {brochureURL &&
                  <div className="case-classification--download-brochure">
                    <a
                      style={{justifyContent: 'flex-end'}}
                      href={brochureURL}
                      download
                      onClick={() =>
                        ReactGA.event ({
                          category: 'Brochura',
                          action: 'Downloaded',
                        })}
                    >
                      <div className="material-icons">
                        save_alt
                      </div>
                      <div className="case-classification--brochure-download-message">
                        {brochureMessage}
                      </div>
                    </a>
                  </div>}
              </div>
            </div>

          </div>
        : <div className="case-classification--container">

            <div className="case-classification--left-column">
              <h1
                className="case-classification--title"
                style={{
                  marginBottom: '0',
                  fontSize: '58px',
                  letterSpacing: '-0.84px',
                  marginTop: '0',
                }}
              >
                {title}
              </h1>
              <div className="typo-body2 case-classification--main-tags">
                {segmentTag}
              </div>
              <div
                className="case-classification--product-wrapper"
                style={{marginBottom: '24px'}}
              >
                <div className="case-classification--product-logo">
                  <LogoLoader productTypeShort={productTypeShort} />
                </div>
                <div className="case-classification--product-type">
                  {productType}
                </div>
                <div className="typo-body2">{areaTag}</div>
              </div>
              <div className="gray typo-captions case-classification--reading-time">
                {readingTime}
              </div>

            </div>
            <div className="case-classification--right-column">

              <div className="gray typo-body2 case-classification--client">
                <div style={{fontWeight: 'bold'}}>Client</div>
                <div>{client}</div>
              </div>

              <div
                className="case-classification--tag-list-wrapper"
                style={{marginBottom: '24px'}}
              >
                <div
                  style={{fontWeight: 'bold'}}
                  className="gray typo-body2 case-classification--tag-list-title"
                >
                  {locale === 'pt' ? 'Áreas de Intervenção' : 'Solution Types'}
                </div>
                <div className="gray typo-body2 case-classification--tag-list-tags">
                  {tagList}
                </div>
              </div>
              <div className="gray typo-body2">
                {brochureURL &&
                  <div className="case-classification--download-brochure">
                    <a
                      href={brochureURL}
                      download
                      onClick={() =>
                        ReactGA.event ({
                          category: 'Brochura',
                          action: 'Downloaded',
                        })}
                    >
                      <div className="material-icons">
                        save_alt
                      </div>
                      <div className="case-classification--brochure-download-message">
                        {brochureMessage}
                      </div>
                    </a>
                  </div>}
              </div>
            </div>

          </div>}

    </div>
  );
};

export default CaseClassification;
